<div class="mt-10 px-20">
    <div class="col-span-2 pb-10">
      <div class="h-auto bg-white">
        <prism-title additionalClasses="text-4xl pb-10" title="Courses In Progress"></prism-title>
          <div class="flex flex-wrap gap-6">
            @if (userCourses?.length == 0) {
              <h1 class="text-2xl text-dark-purple">You do not currently have any courses started</h1>
            }
            @else {
              <div class="flex flex-wrap gap-12">
                @for (course of userCourses; track $index) {
                  <div class="w-72">
                  <prism-course-card 
                        [course]="course"
                        (click)="viewCourse(course.courseId)">
                  </prism-course-card>
                  </div>
                }
              </div>
            }
          </div>
      </div>
    </div> 
    <div class="col-span-2 pb-10">
      <div class="h-auto bg-white">
        <prism-title additionalClasses="text-4xl pb-10" title="Recommended Courses"></prism-title>
          <div class="flex flex-wrap gap-12">
            @for (course of courses; track $index) {
              <div class="w-72">
                <prism-card [url]="course.thumbnail"
                            [author]="course.author"
                            [title]="course.name"
                            [entity]="course"
                            [sasToken]="sasToken"
                            (cardClick)="viewCourse(course.id)">
                </prism-card>
              </div>
            }
          </div>
      </div>
    </div> 
</div>

